import _ from 'lodash';

export default abstract class BaseBuilder<NodeType> {
  public and(): this {
    return this;
  }

  public abstract build(): NodeType;

  protected clone(): this {
    return _.cloneDeep(this);
  }
}
